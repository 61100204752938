:root {
  --Roboto: "Roboto", sans-serif;
  --dark: #1c2025;
  --white: #fff;
  --primary: #5c6bc0;
  --link: #1464f6;
  --secondary: #e93578;
  --error: #ff4444;
  --disabled: #babac0;
  --borderRadius: 20px;
  --boxShadow: 0 10px 10px 1px rgba(0, 0, 0, 0.1);
  --gradient: linear-gradient(90deg, rgba(255, 136, 136, 1) 0%, rgba(78, 175, 255, 1) 100%);
  --h1_FZ: 36px;
  --h2_FZ: 30px;
  --h3_FZ: 24px;
  --p1_FZ: 18px;
  --cardWidth: 420px;
  @media screen and (max-width: 980px) {
    --h1_FZ: 26px;
    --h2_FZ: 20px;
    --h3_FZ: 18px;
    --p1_FZ: 15px;
    --cardWidth: 290px;
  }
}
::-webkit-scrollbar {
  background-color: var(--white);
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background-color: var(--white);
}
::-webkit-scrollbar-thumb {
  background-color: var(--disabled);
  border-radius: 4px;
}
::-webkit-scrollbar-button {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: var(--Roboto);
  line-height: 1.6;
  color: var(--dark);
  font-size: var(--p1_FZ);
}
a {
  text-decoration: none;
  color: inherit;
}
img {
  max-width: 100%;
}
input[type="file"] {
  display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.container {
  max-width: 1280px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  @media screen and (max-width: 980px) {
    padding: 0 5px;
  }
}
.invisible {
  opacity: 0;
  position: absolute;
  visibility: hidden;
  z-index: -9999;
}
.card {
  box-shadow: var(--boxShadow);
  border-radius: var(--borderRadius);
  overflow: hidden;
  padding: 20px;
}
.link {
  color: var(--link);
}
.mb20 {
  margin-bottom: 20px;
}
